// extracted by mini-css-extract-plugin
export var blogCard = "blog-module--blogCard--LDZCt";
export var blogContainer = "blog-module--blogContainer--8DrQ2";
export var blogImg = "blog-module--blogImg--Qt+sX";
export var blogTitle = "blog-module--blogTitle--KpX9a";
export var content = "blog-module--content--6EXo-";
export var input = "blog-module--input--bfSCI";
export var sortBtnLabel = "blog-module--sortBtnLabel--mlr-Z";
export var tagBtn = "blog-module--tagBtn--c1yrc";
export var tagButtons = "blog-module--tagButtons--Zhbye";
export var tagDropdown = "blog-module--tagDropdown--ujqmq";
export var topHeading = "blog-module--topHeading--WbSHH";